<template>
  <div class="mx-8 my-8">

    <h1 class="text-3xl mb-5">Água e Luz</h1>

    <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12 xl:col-span-2 mb-1 mt-1">
          <router-link :to="`/${route}/form`" type="button" class="transition duration-200 bg-green-500 hover:bg-green-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white py-2  rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center mb-2">
           <span class="inline-block mr-2">Novo registro</span>
          </router-link>
        </div>
        <div class="col-span-12 xl:col-span-2 mb-1 mt-1">
          <button @click="download" class="transition duration-200 bg-blue-300 hover:bg-blue-400 focus:bg-blue-400 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white py-2  rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center mb-2">
            Download
          </button>
        </div>
        <div class="col-span-12 xl:col-span-2 mb-1 mt-1">
          <button @click="$modal.show('importarModal')" class="transition duration-200 bg-yellow-300 hover:bg-yellow-400 focus:bg-yellow-400 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white py-2  rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center mb-2">
            Importar base 
          </button>
        </div>
    </div>

    <div class="grid grid-cols-12 gap-6 mb-3">
      <div class="col-span-12 xl:col-span-3 mb-1 mt-1">
          <label for="obra" class="block text-sm font-medium">Obra</label>
          <select v-model="obra" id="obra" name="obra" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            <option v-for="ob in obras" :key="ob._id" :value="ob._id" ><span v-if="$store.state.user.root">{{ ob.empresa.nome }} - </span>{{ ob.nome }}</option>
          </select>
      </div>
      <div class="col-span-12 xl:col-span-3 mb-1 mt-1">
          <label for="tipo" class="block text-sm font-medium">Tipo</label>
          <select v-model="tipo" id="tipo" name="tipo" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            <option :value="null">Ambos</option>
            <option value="Água">Água</option>
            <option value="Luz">Luz</option>
          </select>
      </div>
      <div class="col-span-12 md:col-span-2 mb-1 mt-1">
        <label for="dataInicio" class="block text-sm font-medium">Data Início</label>
        <datepicker v-model="dataInicio" :language="ptBR" name="dataInicio" input-class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"/>
      </div>
      <div class="col-span-12 md:col-span-2 mb-1 mt-1">
        <label for="dataFim" class="block text-sm font-medium">Data Final</label>
        <datepicker v-model="dataFim" :language="ptBR" name="dataFim" input-class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"/>
      </div>
      <div class="col-span-12 md:col-span-2 mb-1 mt-2">
        <label class="block text-sm font-medium">Buscar por filtro </label>
        <button @click="updateFiltro" class="bg-blue-500 hover:bg-blue-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white py-2  rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">Buscar </button>
      </div>
    </div>

    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12 xl:col-span-12 mb-1 mt-1 text-xl">
          Acumulado: <br>
          <b>Água:</b> {{acumulado.agua}} m³ <br>
          <b>Luz:</b> {{acumulado.luz}} Kwh
      </div>
      <hr>
    </div>

    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <h4 class="font-bold text-base mb-2">{{ total}} registros cadastrados </h4>
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Obra
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Leitura Inicial
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Leitura Final
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Consumo dia
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Consumo noite
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Tipo
                  </th>
                  <th @click="updateSort()" scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 hover:text-gray-700 uppercase tracking-wider cursor-pointer">
                    Data 
                    (
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 px-0 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M7 11l5-5m0 0l5 5m-5-5v12" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 px-0 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M17 13l-5 5m0 0l-5-5m5 5V6" />
                    </svg>
                    )
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Usuário
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Opções
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="item in list" :key="item._id">
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.obra ? item.obra.nome : ''}}
                      </div>
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.leitura ? item.leitura : '0'}}
                      </div>
                    </div>
                  </td>
                  
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.leituraFinal ? item.leituraFinal : '0'}}
                      </div>
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500" :class="item.total < 0 ? 'text-red-500 font-bold' : ''">
                        {{item.total ? `${item.tipo === 'Água' ? `${(item.total).toFixed(2)} m³` : `${(item.total).toFixed(2)} kwh` }` : '0'}}
                      </div>
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500" :class="item.totalNoite < 0 ? 'text-red-500 font-bold' : ''">
                        {{item.totalNoite ? `${item.tipo === 'Água' ? `${(item.totalNoite).toFixed(2)} m³` : `${(item.totalNoite).toFixed(2)} kwh` }` : '0'}}
                      </div>
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.tipo === 'Água' ? 'Água' : 'Luz'}}
                      </div>
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.data | moment("DD/MM/YYYY") }}
                      </div>
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.usuario ? item.usuario.name : ''}}
                      </div>
                    </div>
                  </td>
                 
                  <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">

                    <router-link 
                    :to="`/${route}/form/${item._id}`" 
                    class="text-blue-500 rounded py-1 px-4">
                    Editar
                    </router-link>

                    <button @click="remove(item._id)" class="text-white hover:bg-red-500 bg-red-700 rounded py-1 px-4 mx-1">
                      Excluir
                    </button>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination v-if="total > 0" v-model="query.page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
        </div>
      </div>
    </div>
    <modal name="importarModal" :adaptive="true" :height="'auto'">
      <div class="px-5 py-5">
        <h2 class="text-xl mb-2 font-bold mb-2"> Importar base</h2>
        <div class="grid grid-cols-12 gap-2">
          <div class="col-span-12">
              <a target= "_blank" rel="noreferrer" :href="`/RG-LOG-002._Controle_de_Água.xlsx`" class="text-sm underline">Exemplo arquivo modelo importação</a>
          </div>
          <div class="col-span-6">
            <label for="mes" class="block text-sm font-medium">Mês da leitura</label>
            <select v-model="form.mes" id="mes" name="mes" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                <option value="01">Janeiro</option>
                <option value="02">Fevereiro</option>
                <option value="03">Março</option>
                <option value="04">Abril</option>
                <option value="05">Maio</option>
                <option value="06">Junho</option>
                <option value="07">Julho</option>
                <option value="08">Agosto</option>
                <option value="09">Setembro</option>
                <option value="10">Outubro</option>
                <option value="11">Novembro</option>
                <option value="12">Dezembro</option>
            </select>
          </div>
          <div class="col-span-6">
            <label for="ano" class="block text-sm font-medium">Ano da leitura</label>
            <select v-model="form.ano" id="ano" name="ano" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                <option v-for="idx in 6" :key="idx - 6" :value="$moment().subtract(6 - idx).format('YYYY')">{{ new Date() | moment("subtract", `${6 - idx} years`) | moment("YYYY")  }}</option>
                <option v-for="idx in 5" :key="idx" :value="$moment().add(idx).format('YYYY')">{{ new Date() | moment("add", `${idx} years`) | moment("YYYY")  }}</option>
            </select>
          </div>
          <div class="col-span-12">
            <label for="form_obra" class="block text-sm font-medium">Obra</label>
            <select v-model="form.obra" id="form_obra" name="obra" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <option v-for="ob in obras" :key="ob._id" :value="ob._id" ><span v-if="$store.state.user.root">{{ ob.empresa.nome }} - </span>{{ ob.nome }}</option>
            </select>
          </div>
          <div class="col-span-12">
            <vue-dropzone ref="doc" id="doc" class="w-full" :options="dropzoneOptions" @vdropzone-sending="send" @vdropzone-success="update">
            </vue-dropzone>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { ptBR } from 'vuejs-datepicker/dist/locale'
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import moment from 'moment';

export default {
  data() {
    return {
      ptBR: ptBR,
      route: 'agualuz',
      list: [],
      tipo: null,
      dataInicio: moment().startOf('month').toISOString(),
      dataFim: moment().endOf('month').toISOString(),
      obra: null,
      obras: [],
      perPage: 10,
      sort: { data: -1 },
      query: { page: 1, limit: 10, skip: 0  },
      total: 0,
      acumulado: {
        agua: 0,
        luz: 0,
      },
      form: {
        mes: null,
        ano: null,
        obra: null,
      },
      dropzoneOptions: this.$http.getDropzoneConfig(
        `/agualuz/importar`,
        'post',
      {
        maxFiles: 1,
        addRemoveLinks: true,
        capture: true,
        dictDefaultMessage: 'Clique ou arraste seu arquivo aqui',
        dictRemoveFile: 'Remover',
      }),
    }
  },
  methods: {
    download() {
      window.open(`${process.env.VUE_APP_API}/v1/agualuz/download?token=${this.jwt}&tipo=${this.tipo}&obra=${this.obra}&dataInicio=${this.dataInicio}&dataFim=${this.dataFim}`);
    },
    async start() {

      const listReq = await this.$http.post(`/v1/${this.route}/list`, { obra: this.obra, tipo: this.tipo, dataInicio: this.dataInicio, dataFim: this.dataFim, skip: this.query.skip, limit: this.query.limit, sort: this.sort });
      this.list = listReq.data.data;
      this.total = listReq.data.total;

      if(listReq.data.resultAcumulado && listReq.data.resultAcumulado.agua) this.acumulado.agua = parseFloat(listReq.data.resultAcumulado.agua.total).toFixed(2);
      if(listReq.data.resultAcumulado && listReq.data.resultAcumulado.luz) this.acumulado.luz = parseFloat(listReq.data.resultAcumulado.luz.total).toFixed(2);
    },
    
    async remove(id) {
      await this.$http.delete(`/v1/${this.route}/${id}`);
      this.$vToastify.success("Removido com sucesso!");
      this.start();
    },

    updateFiltro(){
      this.query.page = 1;
      this.query.limit = 10;
      this.query.skip = 0;

      this.query = { page: this.query.page, limit: this.query.limit, skip: this.query.skip, obra: this.obra, tipo: this.tipo, dataInicio: moment(this.dataInicio).toISOString(), dataFim: moment(this.dataFim).toISOString() };
      this.$router.push({ path: `/${this.route}`, query: this.query });
    },

    updateSort() {
      if(this.sort.data === -1) this.sort.data = 1;
      else this.sort.data = -1;
      this.start();
    },

    update(file, response) {
      if(response.success) this.$vToastify.success("Importado com sucesso!");
      else this.$vToastify.error(response.err);
      this.$modal.hide('importarModal');
      this.start();
    },

    send(file, xhr, formData) {
      formData.append('mes', this.form.mes);
      formData.append('ano', this.form.ano);
      formData.append('obra', this.form.obra);
    },

    async setPage(page) {

      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      this.query.page = page;
      this.query.skip = skip;
      this.query.limit = limit;

      console.log("this.query", this.query);
      this.$router.push({ path: `/${this.route}`, query: this.query });
    }
  },
  components: {
    Datepicker,
    vueDropzone
  },
  async beforeRouteUpdate(to, from, next) {
    next();

    if(this.$route.query.obra) this.obra = this.$route.query.obra;
    if(this.$route.query.tipo) this.tipo = this.$route.query.tipo;
    if(this.$route.query.dataInicio) this.dataInicio = this.$route.query.dataInicio;
    if(this.$route.query.dataFim) this.dataFim = this.$route.query.dataFim;

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    await this.start();
  },
  async beforeMount() {

    const reqobras = await this.$http.post('/v1/obras/list');
    this.obras = reqobras.data.data;

    if(this.$route.query.obra) this.obra = this.$route.query.obra;
    if(this.$route.query.tipo) this.tipo = this.$route.query.tipo;
    if(this.$route.query.dataInicio) this.dataInicio = this.$route.query.dataInicio;
    if(this.$route.query.dataFim) this.dataFim = this.$route.query.dataFim;

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
}
</script>